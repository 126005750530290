<template>
  <div class="home">
    <v-container fluid class="mt-md-5 mt-3">
        <v-row>
          <v-col cols="12" class="d-flex justify-space-between">
            <h3>MY TRADES</h3>
          </v-col>
        </v-row>
        <v-row class="mt-4">
            <v-col cols="12" md="4">
                <v-text-field outlined v-model="search" color="orange" label="SEARCH TRADES"></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="4">
                <v-select outlined @change="filterPosts" :items="['All', 'Buy', 'Sell', 'Active', 'Closed']" color="orange" label="FILTER TRADES"></v-select>
            </v-col> -->
        </v-row>
        <v-row v-if="loading">
            <v-col cols="12" class="text-center">
                <v-progress-circular
                    indeterminate
                    color="orange"
                ></v-progress-circular>
            </v-col>
        </v-row>
        <v-data-table
            v-if="!loading"
            :headers="headers"
            :items="posts"
            :search="search"
            :sort-by="['date']"
            :sort-desc="[true]"
            class="elevation-1 mt-4"
        >
          <template v-slot:item.traderId="{ item }">
              <v-chip color="orange" small @click="getTraderInfo(item.user_id)" dark>Trader{{item.traderId}}</v-chip>
          </template> 
          <template v-slot:item.commodity="{ item }">
              <v-chip :color="item.status === 'Active' ? 'info' : 'grey'" small @click="view(item)" dark>{{item.commodity}}</v-chip>
          </template> 
          <template v-slot:item.status="{ item }">
              <v-chip @click="updatePostStatus(item)" :color="item.status === 'Active' ? 'green' : 'red'" dark>{{item.status}}</v-chip>
          </template>
          <template v-slot:item.date="{ item }">
              {{getDate(item.date).date}}
          </template>     
        </v-data-table>

        <v-dialog max-width="500px" v-model="viewModal">
            <v-card v-if="post">
                <v-card-text class="pt-3">
                    <div class="d-flex justify-space-between">
                        <h4>Post Details <v-chip class="ml-3" small :color="post.status === 'Active' ? 'green' : 'red'" dark>{{post.status}}</v-chip></h4>
                        <v-icon @click="viewModal = !viewModal">mdi-close</v-icon>
                    </div>
                    <v-divider class="my-3 grey-lighten-3"></v-divider>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <p><strong class="orange--text">Action</strong> <br> <span>{{post.action}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Commodity</strong> <br> <span>{{post.commodity}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Price</strong> <br> <span>{{post.price}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Delivery</strong> <br> <span>{{post.delivery}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Destination</strong> <br> <span>{{post.destination}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Origin</strong> <br> <span>{{post.origin}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Payment Term</strong> <br> <span>{{post.payment_term}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Date</strong> <br> <span>{{getDate(post.date).date}}</span></p>
                        </v-col>
                        <v-col cols="12">
                            <p><strong class="orange--text">Description</strong> <br> <span>{{post.description}}</span></p>
                        </v-col>
                    </v-row>
                    <v-row v-if="post.user_id !== getLoggedUser().id" >
                      <v-col cols="12" md="6">
                        <v-btn depressed block color="red" @click="submitReport(post)" :loading="reportLoading" :disabled="reportLoading"><v-icon class="pr-2">mdi-alert</v-icon> Report</v-btn>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-btn depressed block color="info" @click="contactModal = !contactModal"><v-icon class="pr-2">mdi-email</v-icon> Connect</v-btn>
                      </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog persistent max-width="500px" v-model="traderModal">
          <v-card>
              <v-card-text class="pt-3">
                  <div class="d-flex justify-space-between">
                      <h4>Trader Profile <v-chip v-if="trader" class="ml-3" small :color="trader.verified ? 'green' : 'red'" dark><v-icon v-if="trader.verified" class="pr-2">mdi-check-circle</v-icon> {{trader.verified ? 'Verified' : 'Not Verified'}}</v-chip></h4>
                      <v-icon @click="traderModal = !traderModal">mdi-close</v-icon>
                  </div>
                  <v-divider class="my-3 grey-lighten-3"></v-divider>
                  <v-row no-gutters v-if="trader">
                      <v-col cols="6">
                          <p><strong class="orange--text">Name</strong> <br> <span>{{trader.name}}</span></p>
                      </v-col>
                      <!-- <v-col cols="6">
                          <p><strong class="orange--text">Email</strong> <br> <span>{{trader.email}}</span></p>
                      </v-col> -->
                      <v-col cols="6">
                          <p><strong class="orange--text">Status</strong> <br> <span>{{trader.status ? 'Active' : 'Blocked'}}</span></p>
                      </v-col>
                      <v-col cols="6">
                          <p><strong class="orange--text">Verified</strong> <br> <span>{{trader.verified ? 'Verified' : 'Not Verified'}}</span></p>
                      </v-col>
                  </v-row>
              </v-card-text>
          </v-card>
      </v-dialog>

      <v-dialog v-model="contactModal" max-width="500px">
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            <span><v-icon class="pr-2">mdi-email</v-icon> Connect <v-chip small v-if="post" color="info">{{post.commodity}}</v-chip></span>
            <v-icon @click="contactModal = !contactModal">mdi-close</v-icon>
          </v-card-title>
          <v-divider class="my-4"></v-divider>
          <v-card-text>
            <v-form ref="connectForm" @submit.prevent>
                <v-row>
                    <v-col cols="12">
                        <v-text-field outlined :rules="emailRules" label="Your Email" color="orange" v-model="connect.email"></v-text-field>
                        <v-text-field outlined :rules="[v => !!v || 'Phone is required']" label="Your Phone (WhatsApp)" color="orange" v-model="connect.phone"></v-text-field>
                        <v-textarea outlined :rules="[v => !!v || 'Message is required']"  label="Your Message" color="orange" v-model="connect.message"></v-textarea>
                        <v-btn color="orange" depressed block @click="submitConnect(post)" :disabled="connectLoading" :loading="connectLoading">Submit</v-btn>
                    </v-col>
                </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="statusModal" max-width="500px">
        <v-card v-if="postUpdate">
          <v-card-title class="d-flex justify-space-between">
            <span>{{postUpdate.status === 'Active' ? 'Close' : 'Active'}} Post</span>
            <v-icon @click="statusModal = !statusModal">mdi-close</v-icon>
          </v-card-title>
          <v-divider class="my-4"></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="text-center">
                <h3>Are you sure you want to <span class="orange--text">{{postUpdate.status === 'Active' ? 'Close' : 'Active'}}</span> this Post ?</h3>
                <v-btn class="mt-4" color="orange" depressed @click="updateStatus(postUpdate)">Yes, {{postUpdate.status === 'Active' ? 'Close' : 'Active'}}</v-btn>
                <v-btn class="mt-4 ml-2" color="red" @click="statusModal = !statusModal" depressed>No, Cancel</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: 'Home',
  computed: {
    ...mapGetters(['getPosts', 'getUsers', 'getReportCreated', 'getConnectCreated'])
  },
  watch: {
      getPosts(val) {
        if(val && val.length) {
            this.loading = false
            this.posts = val.filter(post => post.user_id === this.getLoggedUser().id)
        }
      },
      getReportCreated(val) {
          if(val) {
            this.reportLoading = false
          }
          if(val === 1) {
              this.viewModal = false
          }
      },
      getConnectCreated(val) {
          if(val) {
            this.connectLoading = false
          }
          if(val === 1) {
              this.contactModal = false
              this.$refs.connectForm.reset()
          }
      }
  },
  created() {
    // setTimeout(() => 
    // this.posts = this.getPosts
    // , 2000);
    if(!this.getPosts.length) {
        this.loading = true
        this.$store.dispatch('fetchPosts')
    }
    else {
        this.posts = this.getPosts
    }
  },
  data() {
        return {
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            ],
            search: null,
            postToDelete: null,
            deleteModal: false,
            traderModal: false,
            viewModal: false,
            addModal: false,
            post: null,
            postUpdate: null,
            statusModal: false,
            contactModal: false,
            loading: false,
            trader: null,
            reportLoading: false,
            connectLoading: false,
            connect: {
                email: null,
                phone: null,
                message: null,
                post_id: null,
                date: null,
            },
            headers: [
                {
                    text: "Action",
                    value: "action",
                    sortable: true
                },
                {
                    text: "Commodity",
                    value: "commodity",
                    sortable: true
                },
                {
                    text: "Price",
                    value: "price",
                    sortable: true
                },
                {
                    text: "Payment Term",
                    value: "payment_term",
                    sortable: true
                },
                {
                    text: "Delivery",
                    value: "delivery",
                    sortable: true
                },
                {
                    text: "Destination",
                    value: "destination",
                    sortable: true
                },
                {
                    text: "Origin",
                    value: "origin",
                    sortable: true
                },
                {
                    text: "Status",
                    value: "status",
                    sortable: true
                },
                {
                    text: "Date",
                    value: "date",
                    sortable: true
                },
                {
                    text: "Trader ID",
                    value: "traderId",
                    align: 'center',
                    sortable: true
                },
            ],

            posts: []
        }
    },

    methods: {
      view(post) {
        if(post.status !== 'Active') {
          return
        }
        this.post = Object.assign({}, post)
        this.viewModal = true
      },
      filterPosts(type) {
        if(type === 'Buy') {
            this.posts = this.getPosts.filter(post => post.action === 'Buy')
        }
        if(type === 'Sell') {
            this.posts = this.getPosts.filter(post => post.action === 'Sell')
        }
        if(type === 'All') {
            this.posts = this.getPosts
        }
        if(type === 'Closed') {
            this.posts = this.getPosts.filter(post => post.status === 'Closed')
        }
        if(type === 'Active') {
            this.posts = this.getPosts.filter(post => post.status === 'Active')
        }
      },
      getTraderInfo(id) {
        if(this.getUsers.length) {
        this.trader = this.getUsers.find(user => user.id === id)
        this.traderModal = !this.traderModal
        }
      },
      updatePostStatus(post) {
          if(post.user_id === this.getLoggedUser().id) {
              this.postUpdate = Object.assign({}, post)
              this.statusModal = true
          }
      },
      updateStatus(post) {
          let status = null
          if(post.status === 'Active') {
              status = 'Closed'
          }
          else {
              status = 'Active'
          }
          post.status = status
          this.$store.dispatch('updatePost', post)
          this.statusModal = false
      },
      submitReport(post) {
          const payload = {
              reported_by: this.getLoggedUser().id,
              reported_to: post.user_id,
              post_id: post.id,
              date: new Date().toISOString()
          }
          this.reportLoading = true
          this.$store.dispatch('createReport', payload)
      },
      submitConnect(post) {
          if(!this.$refs.connectForm.validate()) {
            return
          }
          this.connect.post_id = post.id
          this.connect.date = new Date().toISOString()
          this.connectLoading = true
          this.$store.dispatch('createConnect', this.connect)
      },

    }
}
</script>
